
  import { Vue, Component } from "vue-property-decorator";

  @Component
  export default class About extends Vue {
    selectedFaculty = {};
    dialog = false;
    faculty = [
      {
        id: "1",
        name: "Yvette McCoy, MS, CC-SLP, BCS-S",
        image: require("@/assets/faculty-yvette.png"),
        description:
          "Yvette McCoy is a speech-language pathologist with over 25 years experience, specializing in adult neurological rehabilitation with a special interest in dysphagia and stroke rehabilitation. She owns Speak Well Solutions, LLC. She is certified by the American Speech-Language-Hearing Association, a board certified swallowing specialist, MDTP certified Provider, eight time ACE Awardee, mentor and CFY supervisor. She currently serves on the Executive Board for the American Board of Swallowing and Swallowing Disorder and is the Public Relations Committee Chair. She is a member of the Dysphagia Research Society where she has served on the Website Communications and Public Relations Committee. She is co-creator/author of the mobile app ‘Dysphagia Therapy’, and The Adult Dysphagia Clinician’s Pocket Guide: From Neuroanatomy to Clinical Practice by Plural Publishing. Finally, she is an adjunct instructor at the University of Maryland, College Park. She is joining the FDI as part of our MDTP instructor team.",
      },
      {
        id: "2",
        name: "Diane Longnecker, M.S., CCC-SLP, BCS-S",
        image: require("@/assets/faculty-diane-cropped.jpg"),
        description:
          "Diane Longnecker is an Advanced Clinical Specialist for the speech pathology department at Baylor Scott and White Institute for Rehabilitation at Baylor University Medical Center in Dallas, Texas. She also holds her Board Certification in Swallowing and Swallowing Disorders (BCS-S). Diane has been using MDTP since 2014 with a variety of patient populations and across a variety of clinical settings. She has also led the integration of MDTP into routine use for treatment of dysphagia in a large hospital system.",
      },
      {
        id: "3",
        name: "Lisa LaGorio, PhD, MPH, CCC-SLP",
        image: require("@/assets/faculty-lisa.png"),
        description:
          "Assistant Professor and Program Director for the MS in Speech-Language Pathology program at Rush University Medical Center, Chicago, IL. She is the Director of the Rush University Swallowing Research Laboratory where she collaborates with physicians in pediatric neurology, pulmonology, otolaryngology, neuroradiology, and the Rush Alzheimer’s Disease Research Center. Her current research interests include aging effects on swallowing physiology, high-flow nasal cannula effects on swallowing, and elucidating the common/shared neurologic pathways between swallowing and cognition in neurodegenerative diseases. She has been a practicing clinician for more than 20 years specializing in neurorehabilitation and dysphagia rehabilitation across the lifespan; she continues to evaluate and treat adult patients weekly. She has been an NIH-funded researcher, international visiting professor, and member of the SIG-13 Professional Development Committee. Finally, Dr. LaGorio has been an MDTP practitioner since 2006 when she joined Drs. Crary and Carnaby’s’ MDTP research team.",
      },
      {
        id: "4",
        name: "Jessica Gregor, MS, CCC-SLP, BCS-S",
        image: require("@/assets/faculty-jessica.jpg"),
        description:
          "Jessica Gregor obtained her Master's of Science in Speech Pathology at Rush University in Chicago, Illinois in 2010. Over the past decade, Jessica has worked in a variety of medical settings (trauma, intensive care, acute care, acute rehab, long-term care, outpatient) at both VA and private sector hospitals. She holds her Board Certification in Swallowing and Swallowing Disorders (BCS-S). Specific dysphagic populations of interest include head/neck and esophageal cancer, tracheostomy, inflammatory myopathies, and neurodegenerative diseases. She has been a certified MDTP provider since 2015. Jessica is presently the lead swallowing specialist at the Cleveland Clinic in Weston, Florida. She closely collaborates with ENT, Gastroenterology, Pulmonary, and Neurology services for optimal dysphagia intervention.",
      },
      {
        id: "5",
        name: "Mary Burns, MS, CCC-SLP",
        image: require("@/assets/faculty-mary.png"),
        description:
          "Mary Burns, MS, CCC-SLP, is a speech-language pathologist with years of experience working across a variety of medical settings. She specializes in neurological rehabilitation with a special interest in dysphagia. She owns Overland Speech and Swallowing Rehabilitation, LLC and provides mobile FEES across the state of Oregon. She is certified by the American Speech-Language-Hearing Association, an MDTP certified provider, two time ACE recipient, mentor, and community educator.",
      },
      {
        id: "6",
        name: "Sarah Dungan, BA, MSc, SLP-CCC",
        image: require("@/assets/faculty-sarah.png"),
        description:
          "Sarah Dungan is a Speech Pathologist with Select Medical at Baylor Scott and White in Dallas, Tx. She received her SLP master's degree from University of Central Florida. She previously employed as a clinician and research assistant for Center for Upper Aerodigestive Functions at UCF with a primary focus in dysphagia and MDTP. She has worked in a variety of settings including SNF, Acute and is currently in Outpatient Therapy with Baylor Scott and White in the DFW area. She has been practicing MDTP with a variety of patients for several years.",
      },
      {
        id: "7",
        name: "Kendrea L. (Focht) Garand, PhD, CScD, CCC-SLP, BCS-S, CBIS",
        image: require("@/assets/faculty-kendrea.jpg"),
        description:
          "Associate Professor in the Department of Communication Science and Disorders at the University of Pittsburgh. She is the Principal Investigator of the Swallowing Disorders Initiative (SDI) Research Laboratory. Her primary research interests include the impacts of aging and progressive neurological conditions on swallowing functions. She has been a practicing speech-language pathologist for over 15 years across various clinical settings, including acute care, inpatient rehabilitation, and outpatient clinics. She holds Board Certification in Swallowing and Swallowing Disorders (BCS-S) and is a Certified Brain Injury Specialist. She became an MDTP certified specialist in 2017. She currently is the Vice Chairperson of the American Board of Swallowing and Swallowing Disorders and Editor for Perspectives for SIG 13 (Swallowing and Swallowing Disorders). Her funded research efforts have resulted in over 60 peer-reviewed publications and hundreds of conference proceedings.",
      },
    ];

    selectFaculty(member) {
      this.selectedFaculty = member;
      this.dialog = true;
    }
  }
